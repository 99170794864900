module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-43665107-11","head":true,"anonymize":true,"respectDNT":true,"exclude":["/preview","/preview/**","/redirect","/redirect/**"],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#005bea"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vivigratis","short_name":"Vivigratis","description":"Offerte e consigli d'acquisto selezionati rispetto a moda, tempo libero, tecnologia, benessere, bellezza, casa e famiglia. Fatti consigliare, confronta e scegli!","start_url":"/","background_color":"#ffffff","theme_color":"#005bea","display":"minimal-ui","icons":[{"src":"/logos/logo-192.png","sizes":"192x192","type":"image/png"},{"src":"/logos/logo-512.png","sizes":"512x512","type":"image/png"},{"src":"/logos/logo-1024.png","sizes":"1024x1024","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    }]
